body{

}

/********************
/* course-list-row
/********************/
.course-rows h5{
    line-height:26px;
    font-size: 18px!important;
}

.course-row-link{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    font-weight:700;
    text-transform:uppercase;
    color:#123;
    line-height:30px;
    font-size: 22px!important;
}
.course-row-link i{
    font-size:20px!important;
    line-height:30px;
}
.course-row-link small{
    font-size:16px!important;
    line-height:30px;
    text-transform:none;
}

/*********************
  stats list
**********************/
.stat-lines-wrp{
    padding:20px 20px;
    border-radius:2px;
    background:#fff;
    font-weight:700;
}

.stat-lines{
    white-space:nowrap;
    text-align:center;
    font-size: 0;
    line-height:10px!important;
}
.stat-lines div{
    display:inline-block;
    margin:0!important;
    padding:1px 0 0 0;
    line-height:18px!important;
    font-size:12px;
    color:rgba(255, 255, 255, 0.75);
    font-weight:700;

}
.stat-lines-wrp .stat-lines div{
    height:26px!important;
}
.stat-lines div:first-child { border-radius: 2px 0 0 2px;}
.stat-lines div:last-child { border-radius: 0 2px 2px 0;}
.stat-lines div:only-child { border-radius: 2px 2px 2px 2px;}
.stat-txt{
    font-family: "mr-eaves-modern", Tahoma, Geneva, sans-serif;
    display:block;
    text-align: left;
    margin-top:10px;
    text-transform: uppercase;
    font-size:16px;
}
.stat-txt div{
    padding-top:1px;
    padding-bottom:1px;
}
.stat-txt div{
    padding-top:1px;
    padding-bottom:1px;
}
/*
.stat-txt > div:nth-child(1), .stat-txt > div:nth-child(3){
    background:#f0f0f0;
}
*/
/*********************
    SVG loader
**********************/
.svg-loader{
    position:absolute;
    z-index:9999;
    left:50%;
    top:50%;
    width:200px;
    height:200px;
    margin-left:-100px;
    margin-top:-100px;
}
/*info */
.info-content-wrp{
    margin-top:30px;
    margin-bottom:60px;
    text-align:center;
}
.info-content-wrp ul.hash-links{
    width:100%;
    padding:0;
    text-align:left;
    list-style-type: none;
    margin-bottom:0;
}
.info-content-wrp li{
    height:auto;
    margin-top:10px;
}
.info-content-wrp li a.hash-link{
    font-size:14px;
    padding:10px 20px;
    display:block;
    overflow:hidden;
    word-break: normal;
    border:1px solid #123;
    color:#123;
    font-weight:700;
    border-radius:4px;
}
.info-box{
    border:1px solid #123;
    padding:20px 8% 70px 8%;
    margin-top:30px;
    border-radius:4px;
}
.info-box h2{
    color:#123;
}
.info-box div{
    text-align:left;
    color:#123;
    margin-top:30px;
    font-size:16px;
    box-sizing: border-box;
}
